import React from 'react'
import Email from '../../assets/email_icon.png'

import Logo from '../../assets/logo_cnl.png'
import Phone from '../../assets/call_icon.png'
import Web from '../../assets/web_icon.png'

export default function HomePage() {
    return (<>
        <footer className="footer p-14 md:p-20 bg-white text-gray-500 ">
            <div>
                <img className='h-16 w-16 md:w-16 md:h-16' src={Logo} alt='Ciang Naga Langit Logo' />
                <p className='w-full md:w-3/4 md:ms-2'>Dapatkan buah-buahan impor berkualitas tinggi dengan harga bersahabat.<br /> <br /> <br />©Copyright PT Ciang Naga Langit. 2023.</p>
            </div>
            <div>
                <span className="footer-title opacity-100 cnl-red">Kontak Kami</span>
                <a class="link link-hover inline-flex items-center "><img className='h-4 w-4 me-2' src={Email} alt='Ciang Naga Langit Logo' /> ciangnagalangit@gmail.com</a>
                <a class="link link-hover inline-flex items-center "><img className='h-4 w-4 me-2' src={Phone} alt='Ciang Naga Langit Logo' /> (031) 99718984</a>
                <a class="link link-hover inline-flex items-center "><img className='h-4 w-4 me-2' src={Web} alt='Ciang Naga Langit Logo' /> www.ciangnagalangit.co.id</a>
            </div>
        </footer>
    </>
    );
}