import React from 'react'
import ProductImage from "../../../assets/products_image.png"
export default function SectionProduct() {
    return (<>
        <div id="section3_mobile" className="container py-20 px-5 md:px-6 bg-red-custom md:hidden lg:hidden">
            <div className="columns-1">
                <div className="col">
                    <h2 className='mb-4 text-3xl font-bold text-white'>Produk & Layanan</h2>
                    <ul className='list-disc mb-5 px-5 text-white'>
                        <li>
                            <h3 className='text-xl font-bold text-white'>Distribusi Buah Impor</h3>
                            <p className='mb-4 text-white'>
                                Sebagian besar produk diimpor langsung dari China, Afrika, Amerika, Australia, dan beberapa Negara lain. Produk didistribusikan ke seluruh pulau Jawa, pulau Bali, pulau Kalimantan, pulau Sulawesi dan sekitarnya.
                            </p>
                        </li>
                        <li>
                            <h3 className='text-xl font-bold text-white'>Ekspedisi</h3>
                        </li>
                    </ul>

                </div>
                <div className="col">
                    <img src={ProductImage} class="mb-6 mx-auto w-full" alt="aboutcnl" />
                </div>
            </div>
        </div>
        <div id="section3" className="py-32 px-20 md:px-6 bg-red-custom hidden md:flex">
            <div className="columns-2 flex items-center">
                <div className="px-10">
                    <h2 className='mb-4 text-3xl font-bold text-white'>Produk & Layanan</h2>
                    <ul className='list-disc mb-5 px-5 text-white'>
                        <li>
                            <h3 className='text-xl font-bold text-white'>Distribusi Buah Impor</h3>
                            <p className='mb-4 text-white'>
                                Sebagian besar produk diimpor langsung dari China, Afrika, Amerika, Australia, dan beberapa Negara lain. Produk didistribusikan ke seluruh pulau Jawa, pulau Bali, pulau Kalimantan, pulau Sulawesi dan sekitarnya.
                            </p>
                        </li>
                        <li>
                            <h3 className='text-xl font-bold text-white'>Ekspedisi</h3>
                        </li>
                    </ul>

                </div>
                <div className="align-middle">
                    <img src={ProductImage} class=" mx-auto mb-6 w-3/4" alt="aboutcnl" />
                </div>
            </div>
        </div>
    </>);
}