import React from 'react'
import Logo from '../../assets/logo_cnl.png'
import { Link, animateScroll as scroll } from "react-scroll";
export default function Navbar() {
    return (
        <div className="navbar bg-base-100 sticky top-0 z-50">
            <div div className="navbar-start" >
                {/* <div className="dropdown">
                    <label tabIndex={0} className="btn btn-ghost lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                    </label>
                    <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                        <li><Link
                            activeClass="active"
                            to="section1"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                        >
                            Home
                        </Link>
                        </li>
                        <li><Link
                            activeClass="active"
                            to="section2_mobile"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                        >
                            About Us
                        </Link>
                        </li>
                        <li><Link
                            activeClass="active"
                            to="section3_mobile"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                        >
                            Product & Services
                        </Link>
                        </li>
                        <li><Link
                            activeClass="active"
                            to="section4"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                        >
                            Visoin & Mission
                        </Link>
                        </li>
                        <li><Link
                            activeClass="active"
                            to="section5"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                        >
                            Contact Us
                        </Link>
                        </li>
                    </ul>
                </div> */}
                <img className='h-12 w-12 md:w-16 md:h-16' src={Logo} alt='Ciang Naga Langit Logo' />
            </div >
            <div className="navbar-center hidden lg:flex px-3">
                <ul className="menu menu-horizontal px-1 gap-4">
                    <li><Link
                        activeClass="active"
                        to="section1"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                    >
                        Utama
                    </Link>
                    </li>
                    <li><Link
                        activeClass="active"
                        to="section2"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >
                        Tentang Kami
                    </Link>
                    </li>
                    <li><Link
                        activeClass="active"
                        to="section3"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >
                        Produk & Layanan
                    </Link>
                    </li>
                    <li><Link
                        activeClass="active"
                        to="section4"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                    >
                        Visi & Misi
                    </Link>
                    </li>
                    <li><Link
                        activeClass="active"
                        to="section5"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                    >
                        Hubungi Kami
                    </Link>
                    </li>
                </ul>
            </div>
            <div className="navbar-end">
                <a href='https://wa.me/6285246597689' target='_blank' className="btn btn-error bg-red-500 text-white">Hubungi Kami</a>
            </div>
        </div >
    );
}