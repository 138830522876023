import React from 'react'
import One from "../../../assets/1_no.png"
import Two from "../../../assets/2_no.png"
import Three from "../../../assets/3_no.png"
import Four from "../../../assets/4_no.png"

export default function SectionVision() {
    return (<>
        <div className="py-32 px-5 md:px-6 bg-section-vision bg-no-repeat text-center">
            <div className="columns-1">
                <h2 id="section4" className='mb-4 text-3xl font-bold '>Visi</h2>
                <p className='mb-8 text-xl font-normal'>
                    “Menjadi perusahaan Ekspor-Impor produk berkualitas tinggi yang terkemuka di Indonesia”
                </p>
                <h2 className='mb-6 text-3xl font-bold '>Misi</h2>
                <div className="grid grid-col-1 md:grid-cols-4 px-10 gap-5">
                    <div className=''>
                        <div className="card bg-base-100 shadow-xl items-center text-center md:flex md:items-start md:text-left ">
                            <figure className="px-10 pt-10 md:px-0 md:ms-8">
                                <img src={One} alt="mission" className="mx-auto h-16 w-16 rounded-full" />
                            </figure>
                            <div className="card-body">
                                <p className='text-gray-500 text-xl font-normal'>Memberikan produk berkualitas dengan harga kompetitif dan bermanfaat demi memastikan kepuasan pelanggan dan membina hubungan baik dengan mitra berkelanjutan</p>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="card bg-base-100 shadow-xl items-center text-center md:items-start md:text-left">
                            <figure className="px-10 pt-10 md:px-0 md:ms-8">
                                <img src={Two} alt="mission" className="h-16 w-16 rounded-full" />
                            </figure>
                            <div className="card-body">
                                <p className='text-gray-500 text-xl font-normal'>Merancang ekosistem terintegrasi yang dapat menyangga bisnis utama dalam perdagangan</p>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="card bg-base-100 shadow-xl items-center text-center md:items-start md:text-left">
                            <figure className="px-10 pt-10 md:px-0 md:ms-8">
                                <img src={Three} alt="mission" className="h-16 w-16 rounded-full" />
                            </figure>
                            <div className="card-body">
                                <p className='text-gray-500 text-xl font-normal'>Melakukan perdagangan umum dan khusus untuk produk pangan maupun non-pangan dengan sasaran pasar domestik hingga Internasional</p>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="card bg-base-100 shadow-xl items-center text-center md:items-start md:text-left">
                            <figure className="px-10 pt-10 md:px-0 md:ms-8">
                                <img src={Four} alt="mission" className="h-16 w-16 rounded-full" />
                            </figure>
                            <div className="card-body">
                                <p className='text-gray-500 text-xl font-normal'>Meningkatkan produktivitas, ketangkasan, dan kualitas kerja SDM lewat pelatihan dan pengembangan kompetensi.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}