import React from 'react'


export default function Hero() {
    return (
        <div id="section1" className="hero h-full md:h-screen bg-hero">
            <div className="hero-content flex-col lg:flex-row-reverse">
                <div className="md:w-3/4 lg:w-3/4"></div>
                <div>
                    <h1 className="text-4xl font-bold">Buah Import Berkualitas dan Sudah Terpercaya dari PT. Ciang Naga Langit</h1>
                    <p className="py-4 md:py-4 text-md md:text-xl text-gray-500">Dapatkan buah-buahan impor berkualitas tinggi dengan harga bersahabat</p>
                    <a href='https://wa.me/6285246597689' target='_blank'>
                        <button className="btn btn-error text-white bg-red-500">
                            Pesan Sekarang
                            <svg className='w-6 h-6 stroke-white' xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" /></svg>
                        </button>
                    </a>
                </div>

            </div>
        </div >
    );
}