import { useRef } from 'react'

export default function SectionContact() {
    const InpName = useRef(null);
    const InpPerusahaan = useRef(null);
    const InpNoTelp = useRef(null);

    function handleContact() {
        var name = InpName.current.value;
        var perusahaan = InpPerusahaan.current.value;
        var noTelp = InpNoTelp.current.value;

        console.log(name + perusahaan + noTelp)
        window.open('https://api.whatsapp.com/send?phone=6285246597689&text=Hallo!%20Saya%20' + name + '(' + noTelp + ')' + '%20dari%20' + perusahaan + '%20saya%20ingin%20memesan%20buah.', '_blank').focus()
    }

    return (<>
        <div id="section5" className="py-20 px-5 md:px-20 bg-no-repeat text-center">

            <div className="columns-1">
                <div className="card h-full rounded-xl bg-top shadow-xl bg-contact">
                    <div className="card-body">
                        <p className='text-xl mb-2 text-red-500'>Kontak Kami</p>
                        <h2 className='mb-4 text-3xl font-bold '>Tertarik untuk Bekerja sama dengan Kami?</h2>
                        <input ref={InpName} type="text" placeholder="Nama" required className="input mb-3 input-bordered w-full max-w-sm self-center" />
                        <input ref={InpPerusahaan} type="text" placeholder="Nama Perusahaan" required className="input mb-3 input-bordered w-full max-w-sm self-center" />
                        <input ref={InpNoTelp} type="number" placeholder="No Telpon" required className="input mb-3 input-bordered w-full max-w-sm self-center" />
                        <button className="btn btn-error text-white w-full max-w-sm self-center" onClick={handleContact}>Submit</button>
                    </div>
                </div>
            </div>

        </div>
    </>);
}