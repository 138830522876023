import React from 'react'
import AboutImage from "../../../assets/aboutus_image.png";

export default function SectionAbout() {
    return (<>
        <div id="section2_mobile" className="py-20 px-5 md:px-6 md:hidden">
            <div className="columns-1">
                <div className="">
                    <h2 className='mb-4 text-3xl font-bold md:text-4xl'>Tentang Kami</h2>
                    <p className='mb-4 text-gray-500'>
                        PT. CIANG NAGA LANGIT adalah perusahaan yang berdiri pada tahun 2021 dengan fokus pada bidang ekspor-impor produk berkualitas tinggi. Kami memiliki visi untuk menjadi pelaku utama dalam industri ini di Indonesia. Melalui produk dan layanan, kami berkomitmen untuk memberikan nilai tambah bagi pelanggan kami dan menjalin hubungan yang berkelanjutan dengan mitra bisnis kami.
                        <br /><br />
                        PT. CIANG NAGA LANGIT berhasil mencetak penjualan senilai 110 Miliar rupiah dari penjualan buah Impor.
                        <br /><br />
                        Dengan dukungan ekosistem terintegrasi, kami siap memenuhi permintaan pasar domestik dan internasional untuk produk pangan dan nonpangan. Dengan didukung oleh tim yang berkualitas dan berdedikasi, kami berupaya untuk terus meningkatkan kualitas dan produktivitas dalam memberikan layanan terbaik bagi pelanggan kami.</p>
                </div>
                <div className="self-center md:p-10">
                    <img src={AboutImage} class="mb-6 mx-auto w-full" alt="aboutcnl" />
                </div>
            </div>
        </div>

        <div id="section2" className="py-20 px-5 md:px-6 hidden md:flex ">
            <div className="columns-2">
                <div className="self-center p-10">
                    <img src={AboutImage} class="mb-6 mx-auto w-full" alt="aboutcnl" />
                </div>
                <div className="p-10 align-middle">
                    <h2 className='mb-4 text-3xl font-bold md:text-4xl'>Tentang Kami</h2>
                    <p className='mb-4 text-gray-500'>
                        PT. CIANG NAGA LANGIT adalah perusahaan yang berdiri pada tahun 2021 dengan fokus pada bidang ekspor-impor produk berkualitas tinggi. Kami memiliki visi untuk menjadi pelaku utama dalam industri ini di Indonesia. Melalui produk dan layanan, kami berkomitmen untuk memberikan nilai tambah bagi pelanggan kami dan menjalin hubungan yang berkelanjutan dengan mitra bisnis kami.
                        <br /><br />
                        PT. CIANG NAGA LANGIT berhasil mencetak penjualan senilai 110 Miliar rupiah dari penjualan buah Impor.
                        <br /><br />
                        Dengan dukungan ekosistem terintegrasi, kami siap memenuhi permintaan pasar domestik dan internasional untuk produk pangan dan nonpangan. Dengan didukung oleh tim yang berkualitas dan berdedikasi, kami berupaya untuk terus meningkatkan kualitas dan produktivitas dalam memberikan layanan terbaik bagi pelanggan kami.</p>
                </div>

            </div>
        </div>
    </>);
}