import React from 'react'
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Footer from './components/Footer'
import SectionAbout from './components/sections/SectionAbout';
import SectionProduct from './components/sections/SectionProduct'
import SectionVision from './components/sections/SectionVision';
import SectionContact from './components/sections/SectionContact';

export default function HomePage() {
    return (<>
        <Navbar />
        <Hero />
        <SectionAbout />
        <SectionProduct />
        <SectionVision />
        <SectionContact />
        <Footer />
    </>
    );
}